.about {
  height: 100vh;
  width: auto;
  display: grid;
  place-items: center;
}

.about p {
  font-size: calc(clamp(8rem, 12vw, 25rem) / 9);
  line-height: 200%;
}

.about a {
  color: hsl(354, 60%, 42%);
  text-decoration: underline;
  pointer-events: all;
  font-size: 120%;
}

.study-focus {
  position: relative;
  padding: 2px;
  z-index: 10;
}

.study-focus::after {
  content: "";
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 5%;
  left: 0;
  background-color: hsl(0, 61%, 32%);
  z-index: -1;
  
}
