body {
  background-color: hsl(var(--body-main-bg));
  color: hsl(var(--body-main-fg));
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}
