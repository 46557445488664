.background {
  width: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  z-index: -1;
}

.background h1 {
  color: hsl(var(--body-main-fg), 0.1);
  text-align: center;
  width: 100vw;
  font-size: min(calc(clamp(8rem, 12vw, 25rem) * 2), 30vw);
}
