footer {
  width: auto;
  height: 200px;
  text-align: center;
  display: grid;
  place-items: center;
  background-image: linear-gradient(
    hsl(var(--body-main-bg)),
    hsl(var(--body-main-bg)),
    black
  );
}
