@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto+Slab:wght@300;400;500&display=swap');

:root {
  --body-main-bg__dark: 0, 0%, 5%;
  --body-main-bg__light: 0, 0%, 95%;
  --body-main-bg: var(--body-main-bg__dark);

  --body-main-fg__dark: 47, 15%, 90%;
  --body-main-fg__light: 249, 15%, 10%;
  --body-main-fg: var(--body-main-fg__dark);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
}

p,
li {
  font-family: 'Roboto Slab', serif;
}

h1,
h2,
h3,
p {
  pointer-events: none;
}
