.content-area {
  width: auto;
  padding-left: clamp(20px, 10%, 100px);
  box-sizing: border-box;
  min-height: 50vh;
  margin-top: 50px;
}

.content-area h2 {
  line-height: 70px;
  font-weight: 500;
  font-size: clamp(2rem, 2vw, 4rem);
  top: 0;
  width: auto;
}
