.hero {
  --background-dot-color__dark: 0, 0%, 100%;
  --background-dot-color__light: 0, 0%, 0%;
  --background-dot-color: var(--background-dot-color__dark);
  /* 
  --background-dot-lighter__dark: 0, 0%, 100%, 15%;
  --background-dot-lighter__light: 0, 0%, 0%, 15%;

  --background-dot-lighter: hsl(0, 0%, 100%, 15%); */

  --background-dot-x: -1000px;
  --background-dot-y: -1000px;
  --background-dot-size: max(4vw, 4vh);

  --scroll-tip-hover__dark: 0, 0%, 10%;
  --scroll-tip-hover__light: 0, 0%, 90%;
  --scroll-tip-hover: var(--scroll-tip-hover__dark);

  --scroll-tip-focus__dark: 0, 0%, 15%;
  --scroll-tip-focus__light: 0, 0%, 85%;
  --scroll-tip-focus: var(--scroll-tip-focus__dark);

  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;
  background-color: hsl(var(--body-main-bg));
}

.hero-header {
  font-weight: 400;
  font-size: clamp(8rem, 12vw, 25rem);
  opacity: 0;
  animation: fadeIn 1s 2500ms forwards;
  position: sticky;
  top: 0;
}

.hero span {
  font-size: clamp(1rem, 2vw, 2rem);
  display: inline-block;
}

.hero span:nth-child(1) p {
  opacity: 0;
  animation: fadeAndBounceIn 1s 500ms forwards;
}

.hero span:nth-child(2) {
  animation: fadeIn 1s 2000ms forwards;
  opacity: 0;
}

.scroll-tip-container {
  position: absolute;
  bottom: 5vh;
  width: inherit;
  height: 10vh;
  display: grid;
  place-items: center;
  opacity: 0;
  animation: fadeIn 1s 3s forwards;
}

.scroll-tip {
  background: transparent;
  width: clamp(3rem, 5vw, 6rem);
  aspect-ratio: 1;
  border: none;
  fill: hsl(var(--body-main-fg));
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: background-color 200ms, transform 200ms, scale 500ms;
  animation: bounce 2s infinite ease-in-out;
  cursor: pointer;
}

.scroll-tip:hover {
  background-color: hsl(var(--scroll-tip-hover));
  transform: scale(1.1);
  animation-play-state: paused;
}

.scroll-tip:active {
  background-color: hsl(var(--scroll-tip-focus));
}

.scroll-tip:focus {
  outline: none;
}

.scroll-tip-icon {
  width: 100%;
  aspect-ratio: 1;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeAndBounceIn {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
