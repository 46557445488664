.experience-card,
.project-card {
  width: min(calc(100vw - clamp(20px, 10%, 100px) - 50px), 500px);
  min-height: 20vh;
  margin-top: clamp(10px, 15vh, 25px);
  padding: 10px;
  border-bottom: 1px solid hsl(var(--body-main-fg), 0.2);
}

.experience-card h3,
.project-card h3 {
  font-weight: 600;
  font-size: calc(clamp(2rem, 2vw, 4rem) * 0.6);
  display: inline-block;
  position: relative;
}

.experience-card h3:not(.experience-company):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.experience-card .experience-company {
  font-size: calc(clamp(2rem, 2vw, 4rem) * 0.5);
  font-weight: 500;
  padding-left: calc(clamp(50px, 10%, 100px) / 5);
}

.experience-card p,
.project-card p,
.project-card ul {
  padding-left: calc(clamp(50px, 10%, 100px) / 2);
  line-height: 150%;
  margin-bottom: 10px;
}

.project-card a,
.project-card svg {
  display: inline-block;
  pointer-events: all;
  width: calc(clamp(50px, 10%, 100px) * 1.3);
  padding-left: calc(clamp(50px, 10%, 100px) / 2);
}

.project-card svg {
  fill: hsl(var(--body-main-fg), 1);
}
