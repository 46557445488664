.hero-bg {
  --hero-bg: 241, 30%, 50%;

  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 2vh 2vw 2vh 2vw;
  pointer-events: none;
}

@media (orientation: portrait) {
  .hero-bg {
    height: 50vh;
    top: 25vh;
  }
}

.hero-bg-text {
  font-size: 10vw;
  animation: pulse 4s infinite ease-in-out;
  opacity: 2%;
  color: hsl(var(--hero-bg));
}

.hero-bg-text:nth-child(1) {
  animation-delay: 0s;
}

.hero-bg-text:nth-child(2) {
  animation-delay: 1.5s;
}

.hero-bg-text:nth-child(3) {
  animation-delay: 3s;
}

.hero-bg-text:nth-child(4) {
  animation-delay: 4.5s;
}

.hero-bg > .hero-bg-text:first-child {
  text-align: right;
}

.hero-bg > .hero-bg-text:last-child {
  text-align: left;
}

.hero-bg-middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hero-bg-middle .hero-bg-text {
  font-size: 6vw;
  width: auto;
}

@keyframes pulse {
  0% {
    opacity: 0%;
  }

  50% {
    opacity: 30%;
  }

  100% {
    opacity: 0%;
  }
}
